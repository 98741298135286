// @flow
import jwtDecode from 'jwt-decode';
import { Cookies } from 'react-cookie';


/**
 * Original fake authentication backed
 * Checks if user is authenticated
 */
const isUserAuthenticated2 = () => {

    const user = getLoggedInUser();
    if (!user) {
        return false;
    }
    const decoded = jwtDecode(user.token);
    const currentTime = Date.now() / 1000;
    if (decoded.exp < currentTime) {
        console.warn('access token expired');
        return false;
    } else {
        return true;
    }
};

/**
 * Checks if Cognito authenticated
 */
const isUserAuthenticated = () => {

    const user = getLoggedInUser();
    if (!user) {
        console.log('isUserAuthenticated return false', null)
        return false;
    }
    else return true;

    // method 1 doens't work...isAuthenticated continue executing
    /*     try {
            const { attribute } = await Auth.currentAuthenticatedUser();
            console.log('isUserAuthenticated return true')
            if (attribute) return true;
        }
        catch (error) {
            console.log('isUserAuthenticated error', error);
            return false
        }
     */
    // method 2 doens't work either for same above reason
    /*     Auth.currentAuthenticatedUser({
            bypassCache: false  // Optional, By default is false. If set to true, this call will send a request to Cognito to get the latest user data
        }).then(user => {
            console.log('isUserAuthenticated return true')
            return true
        })
            .catch(err => {
                console.log('isUserAuthenticated error', err);
                return false
            }); */
};

/**
 * Returns the logged in user
 */
const getLoggedInUser = () => {
    const cookies = new Cookies();
    const user = cookies.get('user');
    const r = user ? (typeof user == 'object' ? user : JSON.parse(user)) : null
    r ? console.log(`getLoggedInuser username ${r.username}`)
        : console.log('getLoggedInuser no user object')

    return user ? (typeof user == 'object' ? user : JSON.parse(user)) : null;
};

export { isUserAuthenticated, getLoggedInUser };
