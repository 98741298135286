/* eslint-disable */
// WARNING: DO NOT EDIT. This file is automatically generated by AWS Amplify. It will be overwritten.

const awsmobile = {
    "aws_project_region": "ap-southeast-2",
    "aws_cognito_identity_pool_id": "ap-southeast-2:20aace68-62fb-4185-a090-d3c2b3635bf1",
    "aws_cognito_region": "ap-southeast-2",
    "aws_user_pools_id": "ap-southeast-2_Knays0Yhp",
    "aws_user_pools_web_client_id": "16hs8g6rjrjbiddih6gvckjv8j",
    "oauth": {
        "domain": "hyperbc79181c-bc79181c-dev.auth.ap-southeast-2.amazoncognito.com",
        "scope": [
            "phone",
            "email",
            "openid",
            "profile",
            "aws.cognito.signin.user.admin"
        ],
        "redirectSignIn": "https://portal.neoncube.com/",
        "redirectSignOut": "https://portal.neoncube.com/",
        "responseType": "code"
    },
    "federationTarget": "COGNITO_USER_POOLS",
    "aws_appsync_graphqlEndpoint": "https://r62tcqebbva2vo4wnqeftug3o4.appsync-api.ap-southeast-2.amazonaws.com/graphql",
    "aws_appsync_region": "ap-southeast-2",
    "aws_appsync_authenticationType": "API_KEY",
    "aws_appsync_apiKey": "da2-rynejfbmtncefprq7ldj5ufpda"
};


export default awsmobile;
